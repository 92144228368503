/*-----------------------------------------------------------------------------/
/ Custom Theme CSS
/-----------------------------------------------------------------------------*/
/*---------------- Global Custom CSS -------------------*/
.t4s-subtitle {
    font-style: normal;
}
[dir=rtl] .t4s-btn-icon {
transform: rotate(180deg);
}
h3.t4s-section-title.t4s-title, span.t4s-section-des.t4s-subtitle {
    font-size: 28px;
    display: inline;
    font-weight: 700;
    line-height: 1.4;
}

.tha-shop-header-list {
padding: 10px 5px;
margin: auto;
}
.tha-shop-header-item-title {
 width: auto;
 height: 25px;
 margin-right: 10px;
background: rgba(0, 0, 0, 0.080);
border-radius: 5px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px 5px;
 outline: none;
 text-decoration: none;
color: #000;
}
a.tha-shop-header-item-title.active.is-selected {
    background: rgb(44 202 185);
}

.t4s-product__title, .t4s-product-title, .t4s-product__description.t4s-rte p, .t4s-text-bl, .t4s-pr-breadcrumb>span {
    unicode-bidi: plaintext;
    text-align: inherit;

}
ol.flickityt4s-page-dots {
    margin-top: 30px !important;
}

[dir=rtl] .frcp-wishlist-btn__icon~.frcp-wishlist-btn__text {
    margin-left: 0 !important;
    margin-right: 8px;
}
.t4s-pa.t4s-frm-search__results {
    display: none;
}
[dir=rtl] #t4s-mini_cart .t4s-drawer__bottom .t4s-btn__cart, #t4s-mini_cart .t4s-drawer__bottom .t4s-btn__checkout {
    letter-spacing: 0px;
}
t4s-pickup-availability-information__title, .t4s-pickup-availability-information__stock, .t4s-product-pickup__variant, .t4s-pickup-availability-list__address p:last-child, .t4s-pickup-availability-list__phone {
    unicode-bidi: plaintext;
}
.t4s-product-pickup__img {
    border-radius: 5%;
}
.t4s-header__logo img {
    padding-top: 20px;
}
.goeip-flag {
    margin-left: 6px;
}
.geoip-text-block {
    font-size: 14px;
    font-family: unset;
}
.geoip-button-block button {
    font-size: 14px;
    padding: 5px 24px;
    min-width: 120px;
    height: 30px;
}

[dir=rtl] .jdgm-rev__icon {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 8px;
}
.jdgm-row-rating {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.jdgm-rev__title {
    display: block;
    font-size: 120%;
}
.jdgm-form input[type="text"], .jdgm-form input[type="email"], .jdgm-form input[type="url"], .jdgm-form textarea {
    unicode-bidi: plaintext;
}

/*---------------- Custom CSS for only desktop -------------------*/
@media (min-width: 1025px) {
  
}

/*---------------- Custom CSS for tablet, mobile -------------------*/
@media (max-width: 1024px) {
  .t4s-top-heading.t4s_des_title_1, .tha-shop-header-section {
    text-align: center !important;
}
.tha-shop-header-section {
    margin: auto;
}
iframe#preview-bar-iframe {
    display: none;
}
}

/*---------------- Custom CSS for only tablet -------------------*/
@media (min-width: 768px) and (max-width: 1024px) {
  
}

/*---------------- Custom CSS for only mobile -------------------*/
@media (max-width: 767px){
  .t4s-col-lg-3.t4s-col-md-4.t4s-col-3.t4s-text-end.t4s-col-group_btns.t4s-col-item.t4s-lh-1 {
    margin-right: -15px;
}
}